import { Appointment, Clinic, TimeSlot } from 'kb-shared/types';
import { AppointmentTypeIds } from 'kb-shared/utilities/appointment.helper';
import { analytics } from 'utilities/analytics';

export const bookingAnalytics = {
  track: {
    partnerClinicSearchStarted: () => {
      analytics.track(analytics.EVENTS.PARTNER_CLINIC_SEARCH_STARTED);
    },

    labSelected: (labId?: string) => {
      analytics.track(analytics.EVENTS.LAB_SELECTED, {
        lab_id: labId
      });
    },

    virtualClinicSelected: () => {
      analytics.track(analytics.EVENTS.CLINIC_SELECTED, {
        clinic_name: 'virtual'
      });
    },

    clinicSelected: (clinic: Clinic | null) => {
      analytics.track(analytics.EVENTS.CLINIC_SELECTED, {
        clinic_name: clinic?.name,
        clinic_id: clinic?.id
      });
    },

    timeSlotSelected: (timeSlot: TimeSlot | null, formattedTime = '') => {
      analytics.track(analytics.EVENTS.TIME_SLOT_SELECTED, {
        time_slot_id: timeSlot?.id,
        time_slot_time: formattedTime || ''
      });
    },

    appointmentBookingSucceeded: (appointment: Appointment, creditCardUsed: boolean) => {
      analytics.track(analytics.EVENTS.APPOINTMENT_BOOKING_SUCCEEDED, {
        appointment_category: appointment.appointmentType?.category?.name,
        appointment_type_id: appointment.appointmentType?.id,
        appointment_type_name: appointment.appointmentType?.name,
        appointment_id: appointment.id,
        lab_id: appointment.location?.lab?.id,
        clinic_id: appointment.location?.id,
        used_cc: creditCardUsed
      });
    },

    npvAppointmentBookingSucceeded: (appointment: Appointment, creditCardUsed: boolean) => {
      if (!appointment.appointmentType?.id) return;

      const trackedAppointmentTypeIds = [
        AppointmentTypeIds.FERTILITY_VIRTUAL,
        AppointmentTypeIds.NEW_PATIENT_CONSULT_FERTILITY_PRESERVATION_VIRTUAL,
        AppointmentTypeIds.CONCEPTION_CONSULT_VIRTUAL,
        AppointmentTypeIds.MALE_FERTILITY_CONSULT_VIRTUAL,
        AppointmentTypeIds.MALE_FERTULITY_ASSESSMENT_SEMEN_ANALYSIS,
        AppointmentTypeIds.FERTILITY_APPOINTMENT,
        AppointmentTypeIds.RECIPROCAL_IVF_ASSESSMENT,
        AppointmentTypeIds.NEW_PATIENT_CONSULTATION,
        AppointmentTypeIds.EGG_FREEZING_ASSESMENT,
        AppointmentTypeIds.RECIPROCAL_IVF_CONSULT_VIRTUAL
      ].map(id => parseInt(id, 10));

      const shouldTrack = trackedAppointmentTypeIds.includes(
        parseInt(appointment.appointmentType.id, 10)
      );

      if (!shouldTrack) return;

      analytics.track(analytics.EVENTS.NPV_APPOINTMENT_BOOKING_SUCCEEDED, {
        appointment_id: appointment.id,
        appointment_type: appointment.appointmentType.id,
        appointment_category: appointment.appointmentType.category?.name,
        used_cc: creditCardUsed
      });
    },

    insuranceDetailsCompletedWithData: () => {
      analytics.track(analytics.EVENTS.INSURANCE_DETAILS_COMPLETED, {
        insurance_provided: true
      });
    },

    insuranceDetailsCompletedWithoutData: () => {
      analytics.track(analytics.EVENTS.INSURANCE_DETAILS_COMPLETED, {
        insurance_provided: false
      });
    }
  },

  page: {
    enterInsuranceInformation: () => {
      analytics.page(analytics.PAGES.ENTER_INSURANCE_INFORMATION);
    },

    confirmPurchase: () => {
      analytics.page(analytics.PAGES.CONFIRM_PURCHASE);
    }
  }
} as const;
